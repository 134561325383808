import React, { useState } from "react"
import "./style.css"

const CopyLinkButton = ({ url }) => {
  const [showTooltip, setShowTooltip] = useState(false)

  const copyToClipboard = () => {
    navigator.clipboard.writeText(url).then(() => {
      setShowTooltip(true)
      setTimeout(() => setShowTooltip(false), 2000)
    })
  }

  return (
    <div style={{ position: "relative" }}>
      <a onClick={copyToClipboard}>
        <i className="fas fa-link" />
      </a>
      {showTooltip && (
        <div className="copy-link-button-tooltip">Link Copied!</div>
      )}
    </div>
  )
}

export default CopyLinkButton
