import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import { Col, Container, Row } from "react-bootstrap"
import { PageWrapper } from "~components/Core"
import FooterSection from "~sections/utility/Footer"
import { CopyLinkButton } from "./Component"
import Details from "./style"
import { FacebookShareButton, TwitterShareButton } from "react-share"
import logo from "../../../assets/image/logo/logo.jpg"

const header = {
  headerClasses: "position-relative",
}

export default function BlogDetails({ data, location }) {
  const post = data.markdownRemark
  const { title, description, date } = post.frontmatter
  const url = location.href
  const socialTitle = `${title} - Monsai`

  return (
    <PageWrapper innerPage={true} headerConfig={header}>
      <Helmet>
        <title>{socialTitle}</title>
        <meta name="description" content={description} />
        <meta property="og:title" content={socialTitle} />
        <meta property="og:description" content={description} />
        <meta property="og:url" content={url} />
        <meta property="og:image" content={logo} />
        <meta property="og:image:width" content="200" />
        <meta property="og:image:height" content="200" />

        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content={socialTitle} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={logo} />

        <script type="application/ld+json">
          {`
            "@context": "http://schema.org",
            "@type": "BlogPosting",
            "headline": ${title},
            "url": ${url},
            "datePublished": ${date},
            "dateCreated": ${date},
            "dateModified": ${date},
          `}
        </script>
      </Helmet>
      <Details backgroundColor="#f9fafc">
        <Details.Box pb="60px" pbMD="80px" pbLG="130px">
          <Container>
            <Row className="justify-content-center">
              <Col className="col-lg-7">
                <Details.Box mb="30px" mbLG="55px">
                  <Details.Title as="h1">{title}</Details.Title>
                  <Details.MetaInfo>
                    <Details.Link>{date}</Details.Link>
                  </Details.MetaInfo>
                </Details.Box>
                <Details.Box>
                  <div dangerouslySetInnerHTML={{ __html: post.html }} />
                  <Details.Tag mt="30px">
                    <Details.SubTitle mr="25px" as="h4">
                      Share:
                    </Details.SubTitle>
                    <Details.SocialList>
                      <li>
                        <FacebookShareButton url={url} quote={socialTitle}>
                          <i className="fab fa-facebook" />
                        </FacebookShareButton>
                      </li>
                      <li>
                        <TwitterShareButton
                          url={url}
                          title={socialTitle}
                          via="MonsaiApp"
                        >
                          <i className="fab fa-twitter" />
                        </TwitterShareButton>
                      </li>
                      <li>
                        <CopyLinkButton url={url} />
                      </li>
                    </Details.SocialList>
                  </Details.Tag>
                </Details.Box>
              </Col>
            </Row>
          </Container>
        </Details.Box>
      </Details>
      <FooterSection />
    </PageWrapper>
  )
}

export const query = graphql`
  query BlogQuery($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
        date(formatString: "MMMM DD, YYYY")
      }
    }
  }
`
